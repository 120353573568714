body{
  background-color:transparent;
}

p{
  font-size: 1.15rem;
}


.heroFullWidth{
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  color: white;

  max-width:100%;
  width:100vw;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.hero{
  min-width:100%;
  height:500px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow:hidden;
}

.hero h1{
  text-shadow: 2px 1px 4px rgba(0,0,0,0.37);
}

.hero-404{
  min-width:100%;
  min-height:650px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow:hidden;
}

.hero-video{
  min-width:100%;
  overflow: hidden;
  max-height: 500px;
  display: block;

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

}

.heroHeading{
  /*padding-top:30px;*/
  padding-top:11rem;
  margin-bottom:1.75rem;
  color:#fff;
  font-size: 3.5em;
  text-shadow: 2px 1px 4px rgba(0,0,0,0.5);
  line-height:1;
}

.blogHeroHeading{
  color:#000;
  font-size: 3.5em;
  text-shadow: 2px 1px 4px rgba(0,0,0,0.5);
}

.blogMeta{
  align-content: center;
  display: grid;
}

.blogHeaderImage img{
  width:100%;
  height:500px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow:hidden;
}

.heroFullWidth img{
  max-width:100%;
  width:100vw;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.heroFullWidth h1{
  font-size: 3rem;
  color:#fff;
}

.background{
  background-color: #f3f3f3;
}

.btn{
  padding:.75rem 1.75rem;
  font-size: 1.15rem;
}

.btn-primary{
  background-color: #051a38;
  border: solid 1px #051a38;
}

.btn-primary:hover{
  background-color: #c8c8c8;
  border: solid 1px #c8c8c8;
  color:black;
  box-shadow: 0 0 30px 0 rgba(0,0,0,.15);
  transition: .75s ease;
}

.btn-callout{
  background-color: inherit;
  color:#fff;
  border: 1px solid #fff;
  min-width:250px;
  max-width:100%;
}

.btn-callout:hover{
  background-color: rgba(255,255,255,.5);
  color:#fff;
  transition: .5 ease;
  /*border: 1px solid orange;*/
}

.btn-callToAction{
  border: 1px solid #fff;
  background-color: green;
}

.feature-block{
  width:100%;
  background-color:#f3f3f3;
}

.feature-block img{
  border-radius: 5rem;
}

.feature-block-items{
  padding: 25px 7%;
}

.software-feature-block{
  padding:0px 0px;
}

.software-feature-block img{
  max-width:100%;
  overflow: hidden;
  background-size: cover;
  background-position: 50% 50%;
  min-height:100%;
}

.fade-effect-block:hover .overlay{
  background-color: rgba(243,243,243,.55);
  /*height: 375px;*/
  height:100%;
}

.overlay{
  position: absolute;
  /*top: 0;*/
  bottom: 0;
  left: 0;
  right: 0;
  height:0px;
  width: 100%;
  /*opacity: 0; */
  transition: .75s ease;
  background-color: rgba(243,243,243,0);
  overflow: hidden;
}

.overlay p{
  /*display:none;*/
  position: absolute;
  bottom: 0;
  width:100%;
  height:100px;
  background:rgba(68,68,68,1);
  color:#fff;
  margin-bottom:0px;
  transition: 1.25s ease;
}

.caption-heading{
  position:absolute;
  top:30%;
  left:25%;
  right:25%;
}

.caption-heading h3{
  color:rgba(0,0,0,1);
}

.feature-slider img{
  max-width:100%;
  /*max-height:450px;*/
}

.feature-slider-arrow:hover{
  /*background: #fff;*/
}

.content-area{
  margin: 0 auto;
  width: 75%;
}

.feature-image-text p{

}

.product-sm-card{
  /*max-width:350px !important;*/
  margin: 11px;
}

.product-sm-img img{
  /*height:100px !important;*/
  max-height:100px;
  margin:auto auto;
}

.related-posts{
  display: inline-flex;
}

.related-posts-card{
  /*width: 325px;*/
  margin:25px;
  /*min-width:350px;*/
}

.related-posts-card h4{
  min-height: 65px;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.product-sm-img img:hover{
  background-color: #cecece;
}

.border-img{
  border: 1px solid #cecece;
  padding: 50px 25px;
  box-shadow: 0 1px 4px 0 rgba(32,33,36,0.12),0 2px 2px 0 rgba(32,33,36,0.14),0 3px 1px -2px rgba(32,33,36,0.2);
  background-color: #fff;
}

.related-posts-card:hover{
  box-shadow: 0 0 30px 0 rgba(0,0,0,.15);
  transition: .5s;
  /*transform: scale(1.03); */
}

/* blog section */
.blog-entry p{
  /*color: #c8cec8;*/
  color: #676666;
  text-overflow: ellipsis;
}

.category-sidebar ul{
  text-decoration: none;
  text-align: left;
  list-style-type: none;
  padding-left: 0px;
  line-height: 1.5rem;
}

.category-sidebar ul li a{
  text-decoration: none;
  color: #676666;
}

.theme-line{
  border-top: 2px solid #051a38;
  margin-top: 0px;
}

.page-link{
  color: #051a38;
}

.blog-entry p{
  color:#051a38;
}

.date-text{
  color:#c8c8c8;
}

.blog-entry h2, .blog-entry h3{
  display: inline-block;
  border-bottom: 2px solid #051a38;
}

.tag-list ul{
  list-style-type: none;
  display: inline;
  padding-left:0px;
}

.tag-list ul li{
  display: inline;
}

.tag-list i{
  color:#cecece;
}

.banner{
  padding: 0px 17%;
}

.search-icon{
  position: absolute;
  right: 5px;
  top: calc( 50% - .8em );
}

.subscribe-form{
  padding: 0px 16%;
}

/* drawer form stuff */

.form-overlay{
  background-color: #fff;
  width:100%;
}

.form-overlay h2{
  font-size: 1.75em;
}

.form-popup{
  position: absolute;
  top: 80px;
  right:0;
  z-index:10;
}

/* used in banner to display width of form */
.banner-form-area, .hbspt-form{
  max-width:450px;
  margin-left: auto;
  margin-right: auto;
}

.slide-out-drawer{
  position:absolute;
  top: 80px;
  right:0;
  min-width:75px;
  width:10%;
}

.quick-takes-area{
  height:333px;
}

.quick-takes-banner{
  height:300px;
  background-color: #f3f3f3;

  clip-path: polygon(
      0 0, /* left top */
      100% 0, /* right top */
      100% 100%, /* right bottom */
      0 90% /* left bottom */
    );
  position: relative;
}

.quick-takes-banner h1{
  padding-top:50px;
  text-shadow: 2px 1px 4px rgba(0,0,0,0.37);
}

.banner-video{
  position: relative;
  top:-200px;
}


/*****************************/


/* stat counter */

.counters p{
  padding:25px 30px;
}

.counters h3{
  padding:0px 30px;
  min-height:90px;
}

.counter{
  color:#fff;
  font-size: 4em;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left:50%;
}

.stat-circle{
  position: relative;
  width:175px;
  height:175px;
  border-radius: 50%;
  /*background-color:rgb(0,0,0,.25); */
  background-color: #051a38;
}

.caps{
  text-transform: uppercase;
}

.heading-line{
  width:10%;
  min-width:100px;
  border-top: 4px solid #ffc107;
  margin-top:1px;
}

.left-border{
  border-left: 5px #e4e4e4 solid;
  padding-left: 15px;
}

article.blog-body a{
  color:#051a38;

  /*background-image: linear-gradient(90deg,rgba(93,183,165,.3) 100%,transparent 0);*/
  background: rgb(2,0,36);
  background: -moz-linear-gradient(0deg, rgba(2,0,36,.9) 0%, rgba(206,206,206,.9) 2%);
  background: -webkit-linear-gradient(0deg, rgba(2,0,36,.9) 0%, rgba(206,206,206,.9) 2%);
  background: linear-gradient(0deg, rgba(2,0,36,.9) 0%, rgba(206,206,206,.9) 2%);
  background-color: transparent;
  /*background-position: 0 1.2em;*/
  background-position:bottom;
  background-repeat: repeat-x;
  background-size: 8px 6px;
  transition: background-color .75s,background-image .75s;
  background-blend-mode: lighten;

}

.blog-body a:hover{
  background-color: rgba(255,193,7,0.8);
  background-image: none;
  text-decoration: none;
}

/*****************************/
footer ul{
  padding-left: 0px;
}

footer ul li{
  list-style: none;
  color:#e3e3e3;
  font-size: 12px;
  padding: 0px 10px
}

footer a{
  color:#e3e3e3;
}

footer a :not(.fa):hover{
  color:#c8c8c8;
  text-decoration: none;
}

footer ul li .fab{
  font-size: 2rem;
}

footer{
  min-height: 100px;
  padding:25px 10px;
  color:#e3e3e3;
  /*background:#2C4770;*/
  background: #2c4770;
  background: linear-gradient(#2c4770 20%, #051a38 100%);
  background: -webkit-linear-gradient(#2c4770 20%, #051a38 100%);
  background: -moz-linear-gradient(#2c4770 20%, #051a38 100%);

}

footer h5{
  text-transform: uppercase;
  font-size: 14px;
}

footer a:hover{
  color:#fff;
  text-decoration: none;
}

blockquote .center{
  font-size: 30px;
  font-weight: 800;
  line-height: 1.4;
  color: #1682c6;
  text-align: center;
  margin: 60px 0;
  padding: 0 70px 55px;
  border-left: 2px solid #1682c6;
  border-right: 2px solid #1682c6;
  position: relative;
}

blockquote .left{
  font-size: 30px;
  font-weight: 800;
  line-height: 1.4;
  color: #1682c6;
  text-align: left;
  margin: 60px 0;
  padding: 0 70px 55px;
  border-left: 2px solid #1682c6;
  position: relative;
}



/* CSS - Cascading Style Sheet */
/* Palette color codes */
/* Palette URL: http://paletton.com/#uid=33F0u0kllljaHvYg1qCqFfZv+aE */

/* Feel free to copy&paste color codes to your application */


/* As hex codes */

.color-primary-0 { color: #2C4770 }	/* Main Primary color */
.color-primary-1 { color: #758AA8 }
.color-primary-2 { color: #4C668C }
.color-primary-3 { color: #142E54 }
.color-primary-4 { color: #051A38 }

.color-secondary-1-0 { color: #AA9339 }	/* Main Secondary color (1) */
.color-secondary-1-1 { color: #FFEDAA }
.color-secondary-1-2 { color: #D4BF6A }
.color-secondary-1-3 { color: #7F6A15 }
.color-secondary-1-4 { color: #554400 }

.color-secondary-2-0 { color: #AA6539 }	/* Main Secondary color (2) */
.color-secondary-2-1 { color: #FFCBAA }
.color-secondary-2-2 { color: #D4946A }
.color-secondary-2-3 { color: #7F3F15 }
.color-secondary-2-4 { color: #552100 }

/*.color-gray-0{ color: #c8c8c8 }*/




/* As RGBa codes */

.rgba-primary-0 { color: rgba( 44, 71,112,1) }	/* Main Primary color */
.rgba-primary-1 { color: rgba(117,138,168,1) }
.rgba-primary-2 { color: rgba( 76,102,140,1) }
.rgba-primary-3 { color: rgba( 20, 46, 84,1) }
.rgba-primary-4 { color: rgba(  5, 26, 56,1) }

.rgba-secondary-1-0 { color: rgba(170,147, 57,1) }	/* Main Secondary color (1) */
.rgba-secondary-1-1 { color: rgba(255,237,170,1) }
.rgba-secondary-1-2 { color: rgba(212,191,106,1) }
.rgba-secondary-1-3 { color: rgba(127,106, 21,1) }
.rgba-secondary-1-4 { color: rgba( 85, 68,  0,1) }

.rgba-secondary-2-0 { color: rgba(170,101, 57,1) }	/* Main Secondary color (2) */
.rgba-secondary-2-1 { color: rgba(255,203,170,1) }
.rgba-secondary-2-2 { color: rgba(212,148,106,1) }
.rgba-secondary-2-3 { color: rgba(127, 63, 21,1) }
.rgba-secondary-2-4 { color: rgba( 85, 33,  0,1) }



/* Generated by Paletton.com © 2002-2014 */
/* http://paletton.com */


/* type writer animation */
.typewriter{
  overflow: hidden;
  margin: 0 auto;
  animation:
    typing 3.5s steps(40, end),
    blink-caret .75s step-end infinite;
}

.code-area{
  border:1px #383838 solid;
  background-color:#000;
}

code, code p{
  color:#76ff03 !important;
  font-size:.85em;
}

.deliverables li::before{
  content: "\2713";
  color:green;
  margin-right: 5px;
}

.deliverables{
  list-style: none;
  padding-left:0px;
}

/* parent div for image */
.image-hover-effect{

  overflow: hidden;
  padding:0;

  box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.5);
  -webkit-box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.5);
}

.image-hover-effect img{
  transition: transform 2.5s;
  -moz-transition: transform 2.5s;
  -webkit-transition: transform 2.5s;
  -o-transistion: transform 2.5s;
}

.image-hover-effect:hover img{
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  transform: scale(1.2);
  opacity: 1;
  transition: transform 2.5s;
  -moz-transition: transform 2.5s;
  -webkit-transition: transform 2.5s;
  -o-transistion: transform 2.5s;

}

.form-container{
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.5);
-webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.5);
-moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.5);
min-width:300px;
}


@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: white; }
}

.border-animation {
  --border-width: 2px;
  --animation-speed: 0.5s;
  --color: #ffffff;
  color: var(--color);
  position: relative;
  display: inline-block;
  font-size: 4em;
  line-height: 1em;
  transform: scale(1, 0.8);
  border: var(--border-width) solid transparent;

  .border-animation__inner {
    position: relative;
    display: inline-block;
    width: 100%;
    font-family: 'Six Caps', sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    cursor: pointer;
    padding: 0.2em;
    /* background: rgba(50, 50, 50, 0.5); */
    background: #333;
    z-index: 1;
    border: solid var(--border-width) transparent;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    background: var(--color);
  }

  &:focus:before,
  &:hover:before {
    animation: beforeBorders var(--animation-speed) forwards ease-in-out;
  }

  &:focus:after,
  &:hover:after {
    animation: afterBorders var(--animation-speed) forwards ease-in-out;
  }

  &:focus,
  &:hover {
    animation: borderColors var(--animation-speed) steps(1) forwards;
    outline: none;

    .border-animation__inner {
      animation: background calc(var(--animation-speed) / 5 * 3) forwards ease-in-out;
      animation-delay: calc(var(--animation-speed) / 5 * 2);
    }
  }
}

@keyframes beforeBorders {
  0% {
    top: calc(var(--border-width) * -1);
    left: 50%;
    bottom: auto;
    right: auto;
    width: 0;
    height: var(--border-width);
  }
  33% {
    top: calc(var(--border-width) * -1);
    left: calc(var(--border-width) * -1);
    bottom: auto;
    right: auto;
    width: calc(var(--border-width) + 50%);
    height: var(--border-width);
  }
  66% {
    top: calc(var(--border-width) * -1);
    left: calc(var(--border-width) * -1);
    bottom: auto;
    right: auto;
    width: var(--border-width);
    height: calc((var(--border-width) * 2) + 100%);
  }
  100% {
    top: auto;
    left: calc(var(--border-width) * -1);
    bottom: calc(var(--border-width) * -1);
    right: auto;
    width: calc(var(--border-width) + 50%);
    height: calc((var(--border-width) * 2) + 100%);
  }
}

@keyframes afterBorders {
  0% {
    top: calc(var(--border-width) * -1);
    left: auto;
    bottom: auto;
    right: 50%;
    width: 0;
    height: var(--border-width);
  }
  33% {
    top: calc(var(--border-width) * -1);
    left: auto;
    bottom: auto;
    right: calc(var(--border-width) * -1);
    width: calc(var(--border-width) + 50%);
    height: var(--border-width);
  }
  66% {
    top: calc(var(--border-width) * -1);
    left: auto;
    bottom: auto;
    right: calc(var(--border-width) * -1);
    width: var(--border-width);
    height: calc((var(--border-width) * 2) + 100%);
  }
  100% {
    top: auto;
    left: auto;
    bottom: calc(var(--border-width) * -1);
    right: calc(var(--border-width) * -1);
    width: calc(var(--border-width) + 50%);
    height: calc((var(--border-width) * 2) + 100%);
  }
}

@keyframes borderColors {
  0% {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  33% {
    border-top-color: var(--color);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;

  }
  66% {
    border-top-color: var(--color);
    border-right-color: var(--color);
    border-bottom-color: transparent;
    border-left-color: var(--color);
  }
  100% {
    border-top-color: var(--color);
    border-right-color: var(--color);
    border-bottom-color: var(--color);
    border-left-color: var(--color);
  }
}

@keyframes background {
  to {
    background: #555;
    text-shadow: 0 0.1em 0.1em #111;
  }
}

@media (min-width: 850px) {
  body {
    justify-content: center;
  }
}

@media (min-width: 1200px) {
  .border-animation {
    font-size: 6em;
    line-height: 1em;
  }
}


/* different hover effects */
.fa-youtube:hover{
  color:#FF0000 !important;
}

.fa-github:hover{
  color:#800080 !important;
}

.fa-facebook:hover{
  color:#4267B2 !important;
}

.fa-linkedin:hover{
  color:#0077B5 !important;
}

.fa-instagram:hover{
  color:#405DE6 !important;
}



/* share button overrides */
.at-share-btn:hover{
  background-color:#051A38 !important;
}
